<!--  -->
<template>
<div class='main has-header bg-gray'>
   <mt-header fixed title="用户信息">
      <router-link to="" slot="left">
        <mt-button icon="back" @click.native="$router.back(-1)"></mt-button>
      </router-link>
    </mt-header>
    <ul class="info clearfix">
      <li class="txt-black">
        <span class="txt-gray-lighter label">真实姓名：</span>
        <span class="value">{{user.userName}}</span>
        <i v-if = "user.userSex == 1" class="iconfont icon-boy"></i>
        <i v-if = "user.userSex == 2"  class="iconfont icon-girl"></i>
      </li>
      <li class="txt-black">
        <span class="txt-gray-lighter label">身份证号：</span>
      <span class="value">{{user.idNum}}</span>
      </li>
    </ul>
  
</div>
</template>

<script>
import { getUserInfo } from '@api/user'
export default {
//import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
//这里存放数据
    return {
      user:{
      }
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    let data = {
      userNo : this.$store.getters.getUser.userId,
      Authorization : this.$store.getters.getUser.authorization
    }
    getUserInfo(data).then((res) => {
      this.user = res.data
    }).catch((err) => {
      
    });

  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
ul.info{
  background-color: #fff;
  padding:20px;
  li{
    padding:8px 0;
    font-size: 1rem;
    .label{
      margin-right: 5px;
    }
  }
}
.iconfont{
  margin-left:8px;
}
.icon-boy{
  color:#409EFF
}
.icon-girl{
  color:#F82773
}
</style>